$--color-primary: #2b368b;
$--color-text-primary: #000000;
$--color-text-secondary: #828894;
$--color-text-bold: #000000;
$--color-link: #2b368b;
$--color-tag: #538dff;
$--color-line: #d8dce6;

$--font-size-small: 12px;
$--font-size-base: 14px;
$--font-size-medium: 18px;
$--font-size-large: 24px;
$--font-size-xl: 32px;
$--font-size-2xl: 48px;

$--transition-all: all 450ms ease-in-out;
$--width-page-container: 1440px;
$--width-container: 1240px;
$--min-width-page-container: 1096px;

$--color-warning: #edc20f;
$--color-dangerous: #f54f64;
$--color-success: #65db75;
